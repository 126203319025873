import { FC } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";


const WRAPPER_STYLE = {
  width: "100%",
  height: "100%",
  position: "absolute",
  right: "0",
  bottom: "0",
  zIndex: "9999",
};

const Spinner: FC<{ active: boolean }> = ({ active }) => {
  if (!active) return <></>;

  return (
    <Box sx={WRAPPER_STYLE}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Spinner;
