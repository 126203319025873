import { FC } from "react";
import Chip from "@mui/material/Chip";
import { useRouter } from "next/router";

type Props = {
  label: string,
  href: string,
}

const CHIP_STYLE = {
  margin: '0 .4375rem',
  transition: 'background-color .25s ease-in',
  maxWidth: 'fit-content',
  display: 'inlineFlex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  whiteSpace: 'noWrap',
  outline: 0,
  border: 0,
  cursor: 'pointer',
  fontFamily: 'Work Sans,sans-serif',
  fontSize: '.875rem',
  color: '#4f9e30',
  fontWeight: '500',
  lineHeight: '1.3125rem',
  letterSpacing: 'normal',
  fontStyle: 'normal',
  padding:' 0 .125rem',
  borderadius: '.25rem',
  '@media (min-width: 1024px)': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '.25rem .125rem',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
  }
};

const CustomChip: FC<Props> = ({label, href}) => {
  const router = useRouter()
  const onClick = () => {
    router.push(href);
  }

  return <Chip sx={CHIP_STYLE} label={label} onClick={onClick} />;
}

export default CustomChip;